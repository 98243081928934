import { useEffect, useState, useCallback, useContext } from "react";
import ImageUpload from "./ImageUpload";
import AdminService from "../services/Admin.js";
import LoadingIndicator from "./LoadingIndicator";
import Bloque from "./AdminBloque.jsx";
import Field from "./Field.jsx";
import JSONEditor from "./JSONEditor";
import AdminBar from "./AdminBar";
import ComboSelect from "./ComboSelect";
import { PermissionsContext } from "../context/PermissionsContext";
import { DialogContext } from "../context/DialogContext";
import { APIAdminRequest } from "../services/API";
import L10n from "../services/Locale";
import FormToggle from "./FormToggle.jsx";

export default function AdminGeneral(props) {
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [settings, setSettings] = useState({});
	const [logoImageURL, setLogoImageURL] = useState(null);
	const [iconoImageURL, setIconoImageURL] = useState(null);

	const dialogContext = useContext(DialogContext);
	const permissionsContext = useContext(PermissionsContext);

	useEffect(() => {
		setLoading(true);
		AdminService.GetAllSettings().then((settings) => {
			const newSettings = {};
			for (let setting of settings) {
				newSettings[setting.settings_key] = setting.settings_value;
			}
			setLoading(false);
			setSettings(newSettings);
		});

		document.body.classList.add("bg-grey");

		return () => {
			document.body.classList.remove("bg-grey");
		};
	}, []);

	function changeValue(key, value) {
		const newSettings = { ...settings };
		newSettings[key] = value;
		setSettings(newSettings);
	}

	const save = useCallback(notifySaved => {
		dialogContext.openDialog(L10n.__("¿Quieres guardar los cambios?"), L10n.__("Sí"), L10n.__("No"), (confirmed) => {
			if (!confirmed) {
				notifySaved(false);
				return;
			}

			setSaving(true);
			Promise.all([
				APIAdminRequest("save-uploaded-image", { url: logoImageURL }),
				APIAdminRequest("save-uploaded-image", { url: iconoImageURL })
			]).then(([logoResponse, iconoResponse]) => {
				const s = {...settings};
				
				if (logoResponse.status) s["LOGO"] = logoResponse.data || settings["LOGO"];
				if (iconoResponse.status) s["ICON"] = iconoResponse.data || settings["ICON"];

				AdminService.SetSettingsMultiple(s).then(() => {
					setSaving(false);
					notifySaved(true);
				});
			});
		});
	}, [settings, logoImageURL, iconoImageURL]);

	if (loading || Object.keys(settings).length == 0) return <LoadingIndicator />;

	return (
		<div className="admin-general">
			<AdminBar disabled={saving} onSave={save} />
			<Bloque className="columnas">
				<div className="columna">
					<Field
						title={L10n.__("Email de contacto")}
						type="string"
						defaultValue={settings["CONTACT_EMAIL"]}
						onChange={(value) => {
							changeValue("CONTACT_EMAIL", value);
						}}
					/>
					<Field
						title={L10n.__("Nombre de la marca")}
						type="string"
						defaultValue={settings["BRAND_NAME"]}
						onChange={(value) => {
							changeValue("BRAND_NAME", value);
						}}
					/>
					<Field
						title={L10n.__("Símbolo de divisa")}
						type="string"
						defaultValue={settings["CURRENCY_SYMBOL"] || "€"}
						onChange={(value) => {
							changeValue("CURRENCY_SYMBOL", value);
						}}
					/>
					<label>{L10n.__("Posición del símbolo")}</label>
					<ComboSelect
						small={true}
						options={[
							{ name: L10n.__("Antes"), value: "before" },
							{ name: L10n.__("Después"), value: "after" }
						]}
						defaultValue={settings["CURRENCY_SYMBOL_PLACEMENT"] || "after"}
						onChange={(option) => {
							changeValue("CURRENCY_SYMBOL_PLACEMENT", option.value);
						}}
					/>
				</div>
				<div className="columna" style={{ padding: "20px 20px 55px" }}>
					<Field
						recommendedWidth="250"
						recommendedHeight="65"
						maxFileSize={20 * 1024}
						fileFormats={["png", "svg"]}
						limitsInfoPosition="top-right"
						limitsInfoPositionOffset={["-260px", "0"]}
						alwaysShowLimitsInfo={true}
						className="logo"
						title={L10n.__("Logo")}
						type="image"
						defaultValue={settings["LOGO"]}
						onChange={(value) => {
							setLogoImageURL(value);
						}}
					/>
					<Field
						style={{ width: 94, height: 94 }}
						className="icon"
						title={L10n.__("Icono")}
						recommendedWidth="360"
						recommendedHeight="360"
						maxFileSize={120 * 1024}
						fileFormats={["png", "svg"]}
						limitsInfoPosition="top-right"
						limitsInfoPositionOffset={["-425px", "0"]}
						alwaysShowLimitsInfo={true}
						type="image"
						defaultValue={settings["ICON"]}
						onChange={(value) => {
							setIconoImageURL(value);
						}}
					/>
				</div>
			</Bloque>

			<Bloque className="columnas">
				<div className="columna">
					{settings["SOCIAL_NETWORKS"] !== null && (
						<JSONEditor
							static={true}
							initialValue={settings["SOCIAL_NETWORKS"]}
							properties={[
								{ key: "name", title: L10n.__("Nombre"), type: "string" },
								{ key: "url", title: L10n.__("URL"), type: "string" }
							]}
							onChange={(value) => {
								changeValue("SOCIAL_NETWORKS", value);
							}}
						/>
					)}
				</div>
			</Bloque>

			<Bloque className="columnas">
				<div className="columna">
					<Field
						title={L10n.__("Código de acceso API Facebook")}
						type="string"
						defaultValue={settings["META_ACCESS_TOKEN"]}
						onChange={value => {
							changeValue("META_ACCESS_TOKEN", value);
						}}
					/>
					<Field
						title={L10n.__("ID número de teléfono WhatsApp Business")}
						type="string"
						defaultValue={settings["META_PHONE_NUMBER_ID"]}
						onChange={value => {
							changeValue("META_PHONE_NUMBER_ID", value);
						}}
					/>
				</div>
			</Bloque>
			<Bloque className="columnas">
				<div className="columna">
					<Field
						title={L10n.__("Servidor SMTP")}
						type="string"
						defaultValue={settings["SMTP_HOST"]}
						onChange={(value) => {
							changeValue("SMTP_HOST", value);
						}}
					/>
					<Field
						title={L10n.__("Puerto")}
						type="string"
						defaultValue={settings["SMTP_PORT"]}
						onChange={(value) => {
							changeValue("SMTP_PORT", value);
						}}
					/>
					<Field
						title={L10n.__("Usuario SMTP")}
						type="string"
						defaultValue={settings["SMTP_USER"]}
						onChange={(value) => {
							changeValue("SMTP_USER", value);
						}}
					/>
					<Field
						title={L10n.__("Contraseña SMTP")}
						type="string"
						defaultValue={settings["SMTP_PASSWORD"]}
						onChange={(value) => {
							changeValue("SMTP_PASSWORD", value);
						}}
					/>
					<Field
						title={L10n.__("Teléfono para notificaciones")}
						type="string"
						defaultValue={settings["ADMIN_PHONE"]}
						onChange={(value) => {
							changeValue("ADMIN_PHONE", value);
						}}
					/>
				</div>
			</Bloque>

			<Bloque className="columnas">
				<div className="columna">
					<Field
						title={L10n.__("Tag Google Analytics")}
						type="string"
						defaultValue={settings["GOOGLE_ANALYTICS_TAG"]}
						onChange={(value) => {
							changeValue("GOOGLE_ANALYTICS_TAG", value);
						}}
					/>
					{/*<Field
						title={L10n.__("Clave API Google Passes")}
						type="string"
						defaultValue={settings["GOOGLE_PASSES_API_KEY"]}
						onChange={(value) => {
							changeValue("GOOGLE_PASSES_API_KEY", value);
						}}
					/>*/}
					<Field
						title={L10n.__("Clave API Google para validación de direcciones")}
						type="string"
						defaultValue={settings["GOOGLE_ADDRESS_VALIDATION_API_KEY"]}
						onChange={(value) => {
							changeValue("GOOGLE_ADDRESS_VALIDATION_API_KEY", value);
						}}
					/>
				</div>
			</Bloque>

			<Bloque className="columnas">
				<div className="columna">
					<Field
						title={L10n.__("Merchant ID")}
						type="string"
						defaultValue={settings["TPV_MERCHANT_ID"]}
						onChange={(value) => {
							changeValue("TPV_MERCHANT_ID", value);
						}}
					/>
					<Field
						title={L10n.__("Acquirer BIN")}
						type="string"
						defaultValue={settings["TPV_ACQUIRER_BIN"]}
						onChange={(value) => {
							changeValue("TPV_ACQUIRER_BIN", value);
						}}
					/>
					<Field
						title={L10n.__("ID de terminal")}
						type="string"
						defaultValue={settings["TPV_TERMINAL_ID"]}
						onChange={(value) => {
							changeValue("TPV_TERMINAL_ID", value);
						}}
					/>
					<Field
						title={L10n.__("URL TPV")}
						type="string"
						defaultValue={settings["TPV_URL"]}
						onChange={(value) => {
							changeValue("TPV_URL", value);
						}}
					/>
					<Field
						title={L10n.__("Clave secreta")}
						type="string"
						defaultValue={settings["TPV_SECRET"]}
						onChange={(value) => {
							changeValue("TPV_SECRET", value);
						}}
					/>
				</div>
			</Bloque>

			<Bloque className="columnas">
				<div className="columna">
					<label><FormToggle defaultValue={settings["SHOW_FILTER_HOME"]} onChange={value => {
						changeValue("SHOW_FILTER_HOME", value ? 1 : 0);
					}} /> {L10n.__('Mostrar filtro en la home')}</label>
				</div>
			</Bloque>
		</div>
	);
}
