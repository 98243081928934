import "../../css/home.css";

import React, { useContext, useEffect, useRef, useState } from "react";
import MaterialIcon from "./MaterialIcon.jsx";
import Footer from "./Footer.jsx";
import LoadingIndicator from "./LoadingIndicator.jsx";
import L10n from "../services/Locale";
import Helper from "../services/Helper.jsx";
import { useParams, useNavigate, Link } from "react-router-dom";
import { SubscriptionsContext } from "../context/SubscriptionsContext";
import SubscriptionsService from "../services/Subscriptions.jsx";
import { APIRequest } from "../services/API.js";
import Session from "../services/Session";
import SubscriptionTypesBar from "./SubscriptionTypesBar";
import WidgetFiltro from "./WidgetFiltro";

export default function MisSuscripciones() {
	const [ciudades, setCiudades] = useState([]);
	const [filtersActive, setFiltersActive] = useState(false);
	const [loading, setLoading] = useState(true);
	const [eventsLoading, setEventsLoading] = useState(true);
	const [selectedSubscription, setSelectedSubscription] = useState(null);
	const [negocios, setNegocios] = useState({});
	const [filteredNegocios, setFilteredNegocios] = useState([]);
	const [filteredNegociosSuscripcion, setFilteredNegociosSuscripcion] = useState([]);
	const [subscriptionTypes, setSubscriptionTypes] = useState([]);
	const [pax, setPax] = useState(1);
	const [ciudad, setCiudad] = useState(null);
	const [fecha, setFecha] = useState(null);
	const [availabilityCalendar, setAvailabilityCalendar] = useState({});
	const [overPaxNegocios, setOverPaxNegocios] = useState({});

	const params = useParams();
	const navigate = useNavigate();
	const widgetFiltroResetRef = useRef();
	const widgetFiltroFilterRef = useRef();

	const subscriptionsContext = useContext(SubscriptionsContext);
	const [activeSubscriptionTypeIndex, setActiveSubscriptionTypeIndex] = useState(-1);

	function changeActiveSubscriptionTypeIndex(index) {
		setActiveSubscriptionTypeIndex(index);
	}

	const setSelectedSubscriptionType = (selectedSubscription) => {
		if (!selectedSubscription) return;
		
		SubscriptionsService.SetCartData({
			typeName: selectedSubscription.name,
			typeSlug: selectedSubscription.slug,
			typeID: selectedSubscription.id,
			typeMode: selectedSubscription.pack == 1 ? SubscriptionsService.SUBSCRIPTION_MODE_TIPO : SubscriptionsService.SUBSCRIPTION_MODE_EVENTO
		});
	};

	useEffect(() => {
		let newSubscriptionTypes = [...subscriptionsContext?.subscriptionTypes];
		newSubscriptionTypes.sort((a, b) => {
			let hasA, hasB;

			hasA = subscriptionsContext.userSubscriptions?.filter((s) => s.subscription_type_id == a.id).length > 0;
			hasB = subscriptionsContext.userSubscriptions?.filter((s) => s.subscription_type_id == b.id).length > 0;

			if (hasA && hasB) {
				if (a.menu_order < b.menu_order) return -1;
				if (a.menu_order > b.menu_order) return 1;
				return 0;
			}

			if (hasA && !hasB) return -1;
			if (!hasA && hasB) return 1;

			return 0;
		});

		let same = true;
		for (let i = 0; i < newSubscriptionTypes.length; ++i) {
			if (i >= subscriptionTypes.length || newSubscriptionTypes[i]?.id != subscriptionTypes[i]?.id) same = false;
		}

		if (!same) {
			setEventsLoading(true);
			APIRequest("subscriptions/get-events-for-types", { subscription_type_ids: newSubscriptionTypes.map((t) => t.id).join(",") }).then((response) => {
				Object.keys(response.data).forEach((typeID) => {
					response.data[typeID].forEach((negocio) => {
						negocio.subscription_type_id = typeID;
						negocio.iconos = JSON.parse(negocio.iconos);
						negocio.iconos_detalle = JSON.parse(negocio.iconos_detalle);
					});
				});

				newSubscriptionTypes.forEach((type) => {
					type.available = true;
				});

				Object.keys(response.data).forEach((typeID) => {
					response.data[typeID] = response.data[typeID]?.filter(negocio => subscriptionsContext.userSubscriptions?.filter(subscription => subscription.events?.filter(event => event.negocio_id == negocio.id).length > 0).length > 0);
				});

				setSubscriptionTypes(newSubscriptionTypes.filter(t => {
					if (subscriptionsContext.userSubscriptions.filter(us => us.subscription_type_id == t.id).length > 0) return true;
					if (!t.pack && t.events.filter(ev => ev.event_subscription_data).length == 0) return false;
					if ((t.pack && t.stock == 0) || (!t.pack && t.events.filter(ev => ev.event_subscription_data?.stock != 0).length == 0)) return false;
					return true;
				}));

				setNegocios(response.data);

				let index = 0;
				if (params?.subscription_type_slug && newSubscriptionTypes.find((t) => t.slug == params.subscription_type_slug)) {
					index = newSubscriptionTypes.findIndex((t) => t.slug == params.subscription_type_slug);
				}

				changeActiveSubscriptionTypeIndex(index);
				setSelectedSubscription(newSubscriptionTypes[index]);
				setFilteredNegocios(Object.keys(response.data).reduce((elements, typeID) => elements.concat(response.data[typeID]), []));

				const ciudades = [];
				Object.keys(response.data).forEach((typeID) => {
					response.data[typeID].forEach((negocio) => ciudades.push(negocio.ciudad));
				});
				setCiudades(ciudades);

				setEventsLoading(false);
			});
		}

		if (params?.subscription_type_slug && newSubscriptionTypes.find((t) => t.slug == params.subscription_type_slug)) {
			changeActiveSubscriptionTypeIndex(newSubscriptionTypes.findIndex((t) => t.slug == params.subscription_type_slug));
		} else {
			changeActiveSubscriptionTypeIndex(0);
		}
	}, [subscriptionsContext]);

	useEffect(() => {
		if (activeSubscriptionTypeIndex < 0 || activeSubscriptionTypeIndex >= subscriptionTypes.length) return;
		setSelectedSubscriptionType(subscriptionTypes[activeSubscriptionTypeIndex]);
	}, [ activeSubscriptionTypeIndex]);

	useEffect(() => {
		const negociosArray = Object.keys(negocios).reduce((elements, typeID) => elements.concat(negocios[typeID]), []);

		if (selectedSubscription && negociosArray) {
			if (widgetFiltroFilterRef.current) {
				setFilteredNegocios(widgetFiltroFilterRef.current(negociosArray));
			} else {
				setFilteredNegocios(negociosArray);
			}
		}
	}, [selectedSubscription, widgetFiltroResetRef]);

	useEffect(() => {
		if (activeSubscriptionTypeIndex != -1 && activeSubscriptionTypeIndex < subscriptionTypes.length) {
			setSelectedSubscription(subscriptionTypes[activeSubscriptionTypeIndex]);
		}
	}, [activeSubscriptionTypeIndex, subscriptionTypes]);

	useEffect(() => {
		if (selectedSubscription && filteredNegociosSuscripcion.length > 0) {
			const newOverPaxNegocios = {};
			filteredNegociosSuscripcion.forEach((negocio) => {
				if (negocio.no_calendar) return;

				if (selectedSubscription.pack) {
					if (selectedSubscription.allow_extra_pax) {
						if (SubscriptionsService.IsOverPaxAny(
							subscriptionsContext.userSubscriptions?.filter((sub) => sub.subscription_type_id == selectedSubscription.id),
							pax
						)) newOverPaxNegocios[negocio.id] = true;
					}
				} else if (negocio.events?.filter((ev) => ev.subscription_data.allow_extra_pax).length > 0) {
					if ((pax == -1 ? 1 : pax) > SubscriptionsService.GetMaxPax(subscriptionsContext.userSubscriptions, negocio.id)) newOverPaxNegocios[negocio.id] = true;
				}
			});

			setOverPaxNegocios(newOverPaxNegocios);
		}
	}, [selectedSubscription, filteredNegociosSuscripcion, pax, subscriptionsContext, activeSubscriptionTypeIndex, subscriptionTypes]);

	useEffect(() => {
		if (activeSubscriptionTypeIndex != -1 && activeSubscriptionTypeIndex < subscriptionTypes.length) setFilteredNegociosSuscripcion(filteredNegocios?.filter(negocio => negocio.subscription_type_id == subscriptionTypes[activeSubscriptionTypeIndex].id) ?? []);
	}, [ filteredNegocios ]);

	useEffect(() => {
		Session.CheckIsLoggedIn().then((loggedIn) => {
			if (!loggedIn) {
				Session.RequireLogin();
				return;
			}

			setLoading(true);

			if (subscriptionsContext.userSubscriptions) {
				if (subscriptionsContext.userSubscriptions?.length == 0) {
					changeActiveSubscriptionTypeIndex(-1);
					setLoading(false);
					return;
				}

				if (params?.subscription_type_slug && subscriptionTypes.find((t) => t.slug == params.subscription_type_slug)) {
					changeActiveSubscriptionTypeIndex(subscriptionTypes.findIndex((t) => t.slug == params.subscription_type_slug));
				}

				setLoading(false);
			}
		});
	}, [subscriptionsContext]);

	useEffect(() => {
		if (!subscriptionsContext?.userSubscriptions || subscriptionsContext?.userSubscriptions.length == 0) return;
		const combinedCalendar = {};

		//if (selectedSubscription) {
			subscriptionTypes.forEach(subscriptionType => {
				negocios[subscriptionType.id].forEach(negocio => {
				//negocios[selectedSubscription.id].forEach((negocio) => {
					negocio.events.forEach((event) => {
						const filteredCalendar = SubscriptionsService.FilterCalendar(event.calendar, event.id, subscriptionsContext.userSubscriptions);
						Object.keys(filteredCalendar).forEach((date) => {
							Object.keys(filteredCalendar[date]).forEach((time) => {
								if (filteredCalendar[date][time] < Math.max(1, pax)) {
									return;
								}

								let includedInSubscription = false;
								subscriptionsContext.userSubscriptions?.forEach((userSubscription) => {
									const startDate = Helper.CreateDateCompatible(userSubscription.start_date);
									const endDate = new Date(startDate);
									endDate.setMonth(endDate.getMonth() + 1);
									const calendarDate = Helper.CreateDateCompatible(date);
									if (userSubscription.subscription_type_id == subscriptionType.id && calendarDate >= startDate && calendarDate <= endDate) includedInSubscription = true;
								});

								if (includedInSubscription) {
									if (!combinedCalendar[date]) combinedCalendar[date] = {};
									if (!combinedCalendar[date][time]) combinedCalendar[date][time] = 0;

									combinedCalendar[date][time] += parseInt(filteredCalendar[date][time]);
								}
							});
						});
					});
				});
			});
		//}

		setAvailabilityCalendar(combinedCalendar);
	}, [pax, subscriptionTypes, negocios, selectedSubscription, subscriptionsContext]);

	useEffect(() => {
		const newSubscriptionTypes = [...subscriptionTypes];
		newSubscriptionTypes.forEach((subscriptionType) => {
			subscriptionType.available = true;

			if (pax > 1) {
				subscriptionType.available = false;
				for (let i = 0; i < subscriptionsContext.userSubscriptions?.length; ++i) {
					const userSubscription = subscriptionsContext.userSubscriptions[i];

					if (userSubscription.subscription_type_id == subscriptionType.id) {

						if (userSubscription.visits == -1) {
							subscriptionType.available = true;
							break;
						}
						if (userSubscription.allow_extra_pax || (userSubscription.combinable_visits ? userSubscription.visits : 1) * userSubscription.pax >= pax) {
							subscriptionType.available = true;
							break;
						}
					}
				}
			}

			if (subscriptionType.available)
				negocios[subscriptionType.id].forEach((negocio) => {
					if (negocio.no_calendar) return;
					negocio.events.forEach((event) => {
						if (pax > 1) {
							subscriptionType.available = false;
							event.availability.forEach((availability) => {
								if (availability.pax >= pax) subscriptionType.available = true;
							});
						}
					});
				});
		});

		if (fecha)
			newSubscriptionTypes.forEach((subscriptionType) => {
				if (negocios[subscriptionType.id].filter(negocio => negocio.no_calendar).length > 0) return;
				if (!subscriptionType.available) return;
				subscriptionType.available = false;
				negocios[subscriptionType.id].forEach((negocio) => {
					negocio.events.forEach((event) => {
						event.availability.forEach((availability) => {
							if (availability.slot.split(" ")[0] == Helper.GetISODate(fecha)) subscriptionType.available = true;
						});
					});
				});
			});

		if (ciudad)
			newSubscriptionTypes.forEach((subscriptionType) => {
				if (!subscriptionType.available) return;
				subscriptionType.available = false;
				negocios[subscriptionType.id].forEach((negocio) => {
					if (negocio.city == ciudad) subscriptionType.available = true;
				});
			});

			
		newSubscriptionTypes.sort((a, b) => {
			a.available = a.available && subscriptionsContext.userSubscriptions?.filter((t) => t.subscription_type_id == a.id).length > 0;
			b.available = b.available && subscriptionsContext.userSubscriptions?.filter((t) => t.subscription_type_id == b.id).length > 0;
			
			if (a.available && !b.available) return -1;
			if (!a.available && b.available) return 1;
			if (a.menu_order < b.menu_order) return -1;
			if (a.menu_order > b.menu_order) return 1;
			return 0;
		});

		setSubscriptionTypes(newSubscriptionTypes);
	}, [pax, ciudad, fecha]);

	useEffect(() => {
		setEventsLoading(true);
		
		subscriptionsContext.reload().then(() => {
			setPax(-1);
			setCiudad(null);
			setFecha(null);
		});
	}, []);

	if (loading) return <LoadingIndicator />;

	const noSubscriptions = subscriptionsContext.userSubscriptions && subscriptionsContext?.userSubscriptions.length == 0;

	let maxPax = -1;

	subscriptionsContext.userSubscriptions?.forEach((subscription) => {
		if (subscription.pack) {
			if (subscription.allow_extra_pax) {
				maxPax = Math.max(maxPax, Infinity);
			} else {
				maxPax = Math.max(maxPax, subscription.pax * (subscription.combinable_visits ? subscription.remaining_visits : 1));
			}
		} else {
			if (subscription.allow_extra_pax) {
				maxPax = Math.max(maxPax, Infinity);
			} else {
				maxPax = Math.max(maxPax, subscription.pax * (subscription?.event_subscription_data?.combinable_visits ? subscription.remaining_visits : 1));
			}
		}
	});

	const noAvailability = ((pax > 1 || ciudad || fecha) && filteredNegocios.length == 0) || Object.values(subscriptionTypes).filter(subscriptionType => subscriptionType.available).length == 0;

	return (
		<div className="home mis-suscripciones">		
			<WidgetFiltro
				disabled={noSubscriptions || !selectedSubscription}
				ciudades={ciudades}
				elements={Object.keys(negocios).reduce((elements, typeID) => elements.concat(negocios[typeID]), [])}
				onFiltered={(filtered, filtersActive, ciudad, fecha, pax) => {
					setFilteredNegocios(filtered);
					setFiltersActive(filtersActive);
				}}
				onFilterChanged={(ciudad, fecha, pax) => {
					setPax(pax);
					setCiudad(ciudad);
					setFecha(fecha);
				}}
				combinedCalendar={availabilityCalendar}
				maxPax={maxPax == Infinity ? -1 : maxPax}
				resetRef={(ref) => (widgetFiltroResetRef.current = ref)}
				filterRef={(ref) => (widgetFiltroFilterRef.current = ref)}
				disablePriceFilter={true}
			/>

			{!noSubscriptions && !noAvailability && Object.values(overPaxNegocios).filter((v) => v).length > 0 && <div className="person-alert">{L10n.__("El número de personas seleccionado supera el máximo establecido por alguna de tus suscripciones y solo estarán disponibles con recargo.")}</div>}

			{subscriptionTypes?.length > 0 && (
				<SubscriptionTypesBar
					jumpToFirstSubscribed={true}
					onChange={(idx) => {
						//if (widgetFiltroResetRef.current) widgetFiltroResetRef.current();
						changeActiveSubscriptionTypeIndex(idx);
					}}
					className="with-filter"
					initialValue={activeSubscriptionTypeIndex}
					subscriptionTypes={eventsLoading ? subscriptionTypes.map(type => { type.available = false; return type; }) : subscriptionTypes}
				/>
			)}

			{!noSubscriptions && !noAvailability && selectedSubscription && (
				<div style={{ opacity: eventsLoading ? 0 : 1, transition: "opacity 300ms" }}>
					<div className={"subscription-description has-button"}>
						<Link to={"/reserva/t/" + selectedSubscription.slug} className="cta btn btn-small">
							{L10n.__("+ Info")}
						</Link>
						<div>{selectedSubscription.short_description_subscribers || selectedSubscription.short_description}</div>
					</div>
					<h2 className="selected-subscription-name">{selectedSubscription.name}</h2>
				</div>
			)}

			{noSubscriptions && !eventsLoading && (
				<div className="subscription-description no-subscriptions has-button">
					<div>
						<div>{L10n.__("Actualmente no tienes ninguna suscripción activa.")}</div>
						<Link to="/" className="cta btn btn-small">
							{L10n.__("Añadir suscripciones")}
						</Link>
					</div>
				</div>
			)}

			{!noSubscriptions && !eventsLoading && noAvailability && (
				<div className="subscription-description no-subscriptions">
					<div>
						<div>{L10n.__("No se ha encontrado disponibilidad con esta combinación.")}</div>
					</div>
				</div>
			)}

			{!noSubscriptions && !noAvailability && (
				<div className="negocios-container">
					<section className={"top-list bg-offwhite" + (window.innerWidth < 992 ? " responsive" : "")} style={{ opacity: eventsLoading ? 0 : 1, width: window.innerWidth < 992 && filteredNegociosSuscripcion.length > 1 ? filteredNegociosSuscripcion.length * window.innerWidth * 0.9 : null }}>
						{filteredNegociosSuscripcion.map((negocio, negocioIdx) => {
							let remainingVisits = 0;
							let totalVisits = 0;

							subscriptionsContext.userSubscriptions?.forEach((userSubscription) => {
								if (userSubscription.events?.filter(ev => ev.no_calendar != 1).length == 0) return;
								
								if (selectedSubscription.pack) {
									if (userSubscription.subscription_type_id == selectedSubscription.id) {
										if (userSubscription.remaining_visits > remainingVisits) {
											remainingVisits = userSubscription.remaining_visits * userSubscription.pax;
										}
										totalVisits = userSubscription.visits * userSubscription.pax;
									}
								} else {
									if (userSubscription.events?.filter((ev) => ev.negocio_id == negocio.id).length) {
										if (userSubscription.remaining_visits > remainingVisits) {
											remainingVisits = userSubscription.remaining_visits * userSubscription.pax;
										}
										totalVisits = userSubscription.visits * userSubscription.pax;
									}
								}
							});

							let outOfVisits = false;
							for (let subscription of subscriptionsContext.userSubscriptions) {
								if ((subscription.pack && selectedSubscription.pack && subscription.subscription_type_id == selectedSubscription.id) || (!selectedSubscription.pack && subscription.events?.filter((ev) => !ev.no_calendar && ev.negocio_id == negocio.id).length > 0)) {
									if (subscription.visits == -1) continue;
									outOfVisits = outOfVisits || subscription.remaining_pax === 0 || !subscription.remaining_visits;
								}
							}

							let hasAvailability = negocio.events.reduce((slots, ev) => Object.values(SubscriptionsService.FilterCalendar(ev.calendar, ev.id, subscriptionsContext.userSubscriptions)).length > 0 ? slots.concat(ev.availability.reduce((slots, av) => slots.concat(av.slot), [])) : slots, []).length > 0;
							if (negocio.events.filter(ev => !ev.no_calendar).length == 0) hasAvailability = true;

							return (
								<div
									className="negocio cover-zoom-listener"
									style={{ width: window.innerWidth < 992 && filteredNegociosSuscripcion.length > 1 ? window.innerWidth * 0.9 : null }}
									key={negocio.id}
									onClick={(e) => {
										e.preventDefault();
										navigate("/suscripcion/" + selectedSubscription.slug + "/" + negocio.slug);
									}}>
									<div className="image cover-zoom" style={{ backgroundImage: "url(" + negocio.image_url + ")" }}>
										<div className="iconos">
											{negocio.iconos.map((icono, iconoIdx) => {
												return <img key={icono.image_url} src={icono.image_url} className="has-tooltip icono inhibit-clicks" data-tooltip={icono.description} />;
											})}
										</div>
										{totalVisits > 0 && (
											<div className="tag-visitas has-tooltip inhibit-clicks" data-tooltip={(remainingVisits == 1 ? L10n.__("Te queda") : L10n.__("Te quedan")) + " " + remainingVisits + " " + (remainingVisits == 1 ? L10n.__("visita") : L10n.__("visitas"))}>
												{remainingVisits}/{totalVisits} visitas
											</div>
										)}
										{!overPaxNegocios[negocio.id] && outOfVisits && (
											<div className="tag-out-of-visits inhibit-clicks has-tooltip" data-tooltip={L10n.__("El número de personas seleccionado supera tu suscripción.")}>
												{L10n.__("Límite superado")}
											</div>
										)}
										{!hasAvailability && !outOfVisits && (
											<div className="tag-out-of-visits inhibit-clicks has-tooltip" data-tooltip={L10n.__("No quedan plazas disponibles.")}>
												{L10n.__("Sin disponibilidad")}
											</div>
										)}
										{overPaxNegocios[negocio.id] && (
											<>
												<div className="over-pax has-tooltip inhibit-clicks" data-tooltip={L10n.__("El número de personas seleccionado supera tu suscripción. Reserva sólo disponible con recargo.")}>
													<MaterialIcon name="account_circle" />
													{L10n.__("Disponible con recargo")}
												</div>
											</>
										)}
									</div>
									<div className="name">{negocio.name}</div>
									<div className="city">{negocio.city}</div>
									<div className="iconos-detalle">
										{negocio.iconos_detalle.map((icono, iconoIdx) => {
											return <img src={icono.image_url} key={icono.image_url} className="has-tooltip icono inhibit-clicks" data-tooltip={icono.titulo} />;
										})}
									</div>
								</div>
							);
						})}
					</section>
				</div>
			)}

			<Footer />
		</div>
	);
}
