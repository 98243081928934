import { useState, useEffect, useCallback, useContext } from "react";
import AdminService from "../services/Admin";
import LoadingIndicator from "./LoadingIndicator";
import { StarMeter } from "./Encuesta";
import Table from "./Table";
import Helper from "../services/Helper";
import FormToggle from "./FormToggle";
import FormInput from "./FormInput";
import Field from "./Field";
import MaterialIcon from "./MaterialIcon";
import DoughnutChart from "./estadisticas/DoughnutChart";
import Bloque from "./AdminBloque";
import UI from "../services/UI";
import { APIAdminRequest, APIRequest } from "../services/API";
import L10n from "../services/Locale";

import { DialogContext } from "../context/DialogContext";
import { SubscriptionsContext } from "../context/SubscriptionsContext";

export default function AdminUsuarios(props) {
	const [unsavedChanges, setUnsavedChanges] = useState();
	const [usuarios, setUsuarios] = useState();
	const [editedUser, setEditedUser] = useState();
	const [types, setTypes] = useState();
	const [ratiosByType, setRatiosByType] = useState();
	const [kpis, setKpis] = useState();
	const [countries, setCountries] = useState(null);

	const [selectedUserSubscriptions, setSelectedUserSubscriptions] = useState([]);
	const [selectedUserOrders, setSelectedUserOrders] = useState([]);
	const [openOrderDetailsIndex, setOpenOrderDetailsIndex] = useState(-1);
	const [orderSavingInProgress, setSavingOrderInProgress] = useState(false);

	const dialogContext = useContext(DialogContext);
	const subscriptionsContext = useContext(SubscriptionsContext);

	function getStats(user_id) {
		setTypes(null);
		setRatiosByType(null);
		setKpis(null);

		Promise.all([APIAdminRequest("stats-get-types"), APIAdminRequest("stats-get-user-ratio-by-type", { user_id }), APIAdminRequest("stats-get-user-kpi", { user_id })]).then(([types, ratioByType, KPI]) => {
			setTypes(types.data);
			setRatiosByType(ratioByType.data);
			setKpis(KPI.data);
		});
	}

	useEffect(() => {
		AdminService.GetUsers().then((users) => {
			setUsuarios(users);
		});

		APIRequest("users/register-get-countries").then((response) => {
			setCountries(response.data);
		});

		document.body.classList.add("has-header");

		return () => {
			document.body.classList.remove("has-header");
		};
	}, []);

	if (!usuarios || !countries) {
		return <LoadingIndicator />;
	}

	return (
		<div className="admin-page admin-usuarios">
			<div className="header">
				<h2 style={{ marginRight: "auto" }}>{L10n.__("Listado de usuarios")}</h2>
			</div>
			<Table
				header={[L10n.__("Activo"), L10n.__("Login"), L10n.__("Nombre"), L10n.__("Apellidos"), L10n.__("Teléfono"), L10n.__("País"), L10n.__("Partner"), L10n.__("Admin")]}
				widths={[1, 3, 3, 3, 2, 1, 1, 1]}
				responsiveState={[true, true, false, false, false, false, true, true]}
				responsiveWidths={[1, 2, 0, 0, 0, 0, 1, 1]}
				alignment={["center", "left", "left", "left", "left", "center", "center", "center"]}
				items={usuarios.map((usuario) => ({ data: usuario, columns: [<span className={"boolean-indicator" + (usuario.active ? " active" : "")}></span>, usuario.login, usuario.name, usuario.surname, Helper.FormatPhoneNumber(usuario.phone), countries.find((c) => c.id == usuario.country_id)?.name || "Desconocido", <span className={"boolean-indicator" + (usuario.is_partner ? " active" : "")}></span>, <span className={"boolean-indicator" + (usuario.is_superuser ? " active" : "")}></span>] }))}
				onOpenDetails={(usuario) => {
					setUnsavedChanges(false);
					setEditedUser({ id: usuario.id });
					getStats(usuario.id);

					APIAdminRequest("get-user-orders", { user_id: usuario.id }).then((response) => {
						setSelectedUserOrders(response.data.filter((order) => order.payment_status == 1));
						setOpenOrderDetailsIndex(-1);
					});

					if (subscriptionsContext?.subscriptionsEnabled) {
						APIAdminRequest("get-user-subscriptions", { user_id: usuario.id }).then((response) => {
							setSelectedUserSubscriptions(response.data);
						});
					}
				}}
				willCloseDetails={(answer) => {
					if (!unsavedChanges) {
						answer(true);
						return;
					}

					dialogContext.openDialog(L10n.__("Los cambios no guardados se perderán. ¿Deseas continuar?"), L10n.__("Sí"), L10n.__("No"), (accepted) => {
						answer(accepted);
					});
				}}
				renderDetails={(usuario, idx) => (
					<>
						<div>
							<Field type="string" placeholder={L10n.__("Email")} defaultValue={usuario.login} disabled />
							<Field
								type="password"
								placeholder={L10n.__("Contraseña (dejar en blanco para conservar la actual)")}
								defaultValue=""
								onChange={(value) => {
									const n = { ...editedUser };
									n.newPassword = value;
									setEditedUser(n);
									setUnsavedChanges(true);
								}}
							/>
							<Field
								type="string"
								placeholder={L10n.__("Nombre")}
								defaultValue={usuario.name}
								onChange={(value) => {
									const n = { ...editedUser };
									n.name = value;
									setEditedUser(n);
									setUnsavedChanges(true);
								}}
							/>
							<Field
								type="string"
								placeholder={L10n.__("Apellidos")}
								defaultValue={usuario.surname}
								onChange={(value) => {
									const n = { ...editedUser };
									n.surname = value;
									setEditedUser(n);
									setUnsavedChanges(true);
								}}
							/>
							<Field
								type="string"
								placeholder={L10n.__("Teléfono")}
								defaultValue={Helper.FormatPhoneNumber(usuario.phone)}
								onChange={(value) => {
									const n = { ...editedUser };
									n.phone = value;
									setEditedUser(n);
									setUnsavedChanges(true);
								}}
							/>
							{countries !== null && (
								<FormInput
									type="select"
									id="country"
									initialValue={usuario.country_id}
									options={countries.map((row) => {
										return { label: row.name, value: row.id };
									})}
									onChange={(value) => {
										const n = { ...editedUser };
										n.country_id = value;
										setEditedUser(n);
										setUnsavedChanges(true);
									}}
								/>
							)}
						</div>
						<div className="row-guardar">
							<div>
								{L10n.__("Activo")}{" "}
								<FormToggle
									defaultValue={usuario.active}
									onChange={(value) => {
										const n = { ...editedUser };
										n.active = value;
										setEditedUser(n);
										setUnsavedChanges(true);
									}}
								/>
							</div>
							<div>
								{L10n.__("Partner")}{" "}
								<FormToggle
									defaultValue={usuario.is_partner}
									onChange={(value) => {
										const n = { ...editedUser };
										n.is_partner = value;
										setEditedUser(n);
										setUnsavedChanges(true);
									}}
								/>
							</div>
							<div>
								{L10n.__("Administrador")}{" "}
								<FormToggle
									defaultValue={usuario.is_superuser}
									onChange={(value) => {
										const n = { ...editedUser };
										n.is_superuser = value;
										setEditedUser(n);
										setUnsavedChanges(true);
									}}
								/>
							</div>
							<a
								className="btn btn-brown boton-guardar"
								onClick={(e) => {
									e.preventDefault();
									dialogContext.openDialog(L10n.__("¿Seguro que deseas guardar los cambios?"), L10n.__("Sí"), L10n.__("No"), (accepted) => {
										if (accepted) {
											const n = { ...editedUser };
											if (typeof n.active !== "undefined") n.active = n.active ? 1 : 0;
											if (typeof n.is_partner !== "undefined") n.is_partner = n.is_partner ? 1 : 0;
											if (typeof n.is_superuser !== "undefined") n.is_superuser = n.is_superuser ? 1 : 0;
											APIAdminRequest("save-user", n).then(() => {
												UI.ShowNotification(L10n.__("Cambios guardados con éxito"));
												const ns = [...usuarios];
												for (let k of Object.keys(n)) {
													ns[idx][k] = n[k];
												}
												setUsuarios(ns);
												setUnsavedChanges(false);
											});
										}
									});
								}}>
								{L10n.__("Guardar")}
							</a>
						</div>
						{selectedUserOrders.length > 0 && (
							<div className="row-reservas" key={"reservas-usuario-" + idx}>
								<h2>{L10n.__("Reservas del usuario")}</h2>
								{selectedUserOrders.map((order, order_index) => {
									let orderMeanRating = -1;
									if (order.survey) {
										orderMeanRating = 0;
										let orderRatingsCount = 0;
										order.survey.forEach((survey) => {
											if (survey.value > 0) {
												orderMeanRating += survey.value;
												orderRatingsCount++;
											}
										});
										if (orderRatingsCount > 0) {
											orderMeanRating /= orderRatingsCount;
										}
									}

									return (
										<div className={"user-order-row" + (openOrderDetailsIndex == order_index ? " active" : "")} key={"reserva-usuario-" + idx + "-" + order_index}>
											<div
												className="order-row auto-row"
												onClick={(e) => {
													if (openOrderDetailsIndex == order_index) {
														setOpenOrderDetailsIndex(-1);
													} else {
														setOpenOrderDetailsIndex(order_index);
													}
												}}>
												<div className="date">{Helper.FormatISODate(order.booked_date.split(" ")[0])}</div>
												<div className="negocio-name">{order.event_name}</div>
												<div className="availability-description">{order.availability_description}</div>
												<div className="pax">{order.pax + " persona" + (order.pax != 1 ? "s" : "")}</div>
												{order.details?.time_relevant > 0 && <div className="slot">{order.booked_slot}</div>}
												<div className="align-right amount">{Helper.FormatAmount(order.total / 100)}</div>
												<div className="rating">{<StarMeter showNumeric={true} style={{ opacity: orderMeanRating == -1 ? 0 : 1 }} defaultValue={orderMeanRating} />}</div>
												<div className="validated">{order.validated ? "Validada" : ""}</div>
												<div className="expand-button no-auto-width">
													<MaterialIcon name={openOrderDetailsIndex == order_index ? "expand_less" : "expand_more"} />
												</div>
											</div>
											{openOrderDetailsIndex == order_index && (
												<div className="order-details">
													{order.preorder_selection && order.preorder_selection.length > 0 && (
														<div className="comments">
															<span>{L10n.__("Extras")}</span>
															<div className="extras">
																{order.preorder_selection.map((item, item_idx) => (
																	<div className="extra-item" key={"order-" + order_index + "-item-" + item_idx}>
																		<span>{item.quantity}</span>
																		{item.name}
																	</div>
																))}
															</div>
														</div>
													)}
													{order.comments != null && order.comments.trim() != "" && (
														<div className="comments">
															<span>{L10n.__("Comentarios del cliente")}</span>
															<div>{order.comments}</div>
														</div>
													)}
													<div className="comments">
														<span>{L10n.__("Nota del negocio")}</span>
														<Field
															type="text"
															defaultValue={order.notes}
															onChange={(value) => {
																const newOrders = [...selectedUserOrders];
																newOrders[order_index].notes = value;
																setSelectedUserOrders(newOrders);
															}}
														/>
													</div>
													{order.survey && (
														<div className="comments">
															<span>{L10n.__("Valoración del cliente")}</span>
															<div className="survey">
																{order.survey.map((cat, catIndex) => {
																	return (
																		<div className="category" key={"survey-data-category" + catIndex}>
																			<h3>{cat.text}</h3>
																			<StarMeter showNumeric={true} defaultValue={cat.value} />
																		</div>
																	);
																})}
																{orderMeanRating != -1 && (
																	<div className="category mean-rating">
																		<h3>{L10n.__("Media")}</h3>
																		<StarMeter showNumeric={true} defaultValue={orderMeanRating} />
																	</div>
																)}
															</div>
															{order.survey_comments?.length > 0 && (
																<>
																	<p />
																	<span>{L10n.__("Opinión")}</span>
																	<div>{order.survey_comments}</div>
																</>
															)}
														</div>
													)}
													<div className={"order-actions" + (orderSavingInProgress ? " disabled" : "")}>
														<button
															className="btn"
															onClick={(e) => {
																if (orderSavingInProgress) return;
																dialogContext.openDialog(L10n.__("¿Seguro que deseas cancelar esta reserva?"), L10n.__("Sí"), L10n.__("No"), (accepted) => {
																	if (accepted) {
																		setSavingOrderInProgress(true);
																		AdminService.CancelOrder(order.id).then((status) => {
																			if (status) {
																				const newOrders = [...selectedUserOrders];
																				newOrders.splice(order_index, 1);
																				setSelectedUserOrders(newOrders);
																				setOpenOrderDetailsIndex(-1);
																				UI.ShowNotification(L10n.__("Cambios guardados con éxito"));
																			}
																			setSavingOrderInProgress(false);
																		});
																	}
																});
															}}>
															Cancelar reserva
														</button>
														<button
															className="btn btn-brown"
															onClick={(e) => {
																if (orderSavingInProgress) return;

																dialogContext.openDialog(L10n.__("¿Seguro que deseas guardar los cambios?"), L10n.__("Sí"), L10n.__("No"), (accepted) => {
																	if (accepted) {
																		setSavingOrderInProgress(true);
																		AdminService.UpdateOrder(order.id, order).then(() => {
																			UI.ShowNotification(L10n.__("Cambios guardados con éxito"));
																			setSavingOrderInProgress(false);
																		});
																	}
																});
															}}>
															{L10n.__("Guardar reserva")}
														</button>
													</div>
												</div>
											)}
										</div>
									);
								})}
							</div>
						)}
						{selectedUserSubscriptions.length > 0 && (
							<div className="row-reservas" key={"suscripciones-usuario-" + idx}>
								<h2>{L10n.__("Suscripciones del usuario")}</h2>
								{selectedUserSubscriptions.map((subscription, subscription_index) => {
									let intervalLabel = "";
									if (subscription.event_subscription_data) {
										intervalLabel = Helper.GetIntervalLabel(subscription.event_subscription_data.interval,subscription.event_subscription_data.interval_type);
									} else {
										intervalLabel = Helper.GetIntervalLabel(subscription.subscription_type.interval,subscription.subscription_type.interval_type);
									}
									return (
										<div className={"user-order-row no-details"} key={"suscripcion-usuario-" + idx + "-" + subscription_index}>
											<div className="order-row auto-row">
												<div className="name subscription-name">
													<MaterialIcon
														name="stop_circle"
														tooltip="Detener suscripción"
														onClick={(e) => {
															e.preventDefault();
															dialogContext.openDialog(L10n.__("¿Deseas detener esta suscripción? Para reanudarla, el cliente deberá hacerlo desde su cuenta o suscribirse de nuevo."), L10n.__("Sí"), L10n.__("No"), (accepted) => {
																if (accepted) {
																	APIAdminRequest("cancel-subscription", { user_subscription_id: subscription.id }).then((_response) => {
																		const n = [...selectedUserSubscriptions];
																		n.splice(subscription_index, 1);
																		setSelectedUserSubscriptions(n);
																	});
																}
															});
														}}
													/>
													{subscription.pack ? subscription.subscription_type.name : subscription.event_subscription_data.event_name}
													{subscription.pack == 1 && <span className="pack-tag">{L10n.__("Pack")}</span>}
												</div>
												<div className="pax">{subscription.pax + " persona" + (subscription.pax != 1 ? "s" : "")}</div>
												<div className="visits">
													{subscription.visits == -1 ? "∞" : subscription.visits} {L10n.__("visitas")}/{intervalLabel}
												</div>
												<div className="align-right amount">
													{Helper.FormatAmount(subscription.total_fee / 100)}/{intervalLabel}
												</div>
											</div>
										</div>
									);
								})}
							</div>
						)}
						<div className="row-estadisticas">
							<h2>{L10n.__("Estadísticas de usuario")}</h2>
							{kpis && types && (
								<Bloque className="columnas estadisticas" key={"estadisticas-usuario-" + idx}>
									{types && ratiosByType && ratiosByType.length > 0 && (
										<div className="columna">
											<h2>{L10n.__("Reservas por tipo de negocio")}</h2>
											{<DoughnutChart types={types} ratios={ratiosByType} />}
										</div>
									)}
									{kpis && (
										<div className="columna">
											<h2>{L10n.__("Indicadores de rendimiento")}</h2>
											{subscriptionsContext?.subscriptionsEnabled ? <div className="kpi-container">
												<div className="item">
													<div className="value">{kpis.totalActiveSubscriptions}</div>
													<div className="caption">{L10n.__("Suscripciones activas")}</div>
												</div>
												<div className="item">
													<div className="value">
														{kpis.totalVisits.visits} / {kpis.totalVisits.totalVisits}
													</div>
													<div className="caption">{L10n.__("Visitas")}</div>
												</div>
												<div className="item">
													<div className="value">{(Math.floor(kpis.ordersWithExtrasRatio * 10000) / 100).toLocaleString("es")} %</div>
													<div className="caption">{L10n.__("Reservas con extras")}</div>
												</div>
												<div className="item">
													<div className="value">{(Math.floor(kpis.ordersOverPaxRatio * 10000) / 100).toLocaleString("es")} %</div>
													<div className="caption">{L10n.__("Reservas con más personas")}</div>
												</div>
											</div>
												: <div className="kpi-container">
													<div className="item">
														<div className="value">{Helper.FormatAmount(Math.floor(kpis.averageAmountPerUser / 100))}</div>
														<div className="caption">{L10n.__("Tícket medio")}</div>
													</div>
													<div className="item">
														<div className="value">{kpis.reservas || "0"}</div>
														<div className="caption">{L10n.__("Reservas")}</div>
													</div>
													<div className="item">
														<div className="value">{Math.round(((kpis.upsellingRatio * 100 + Number.EPSILON) * 100) / 100)}%</div>
														<div className="caption">{L10n.__("Conversión upselling")}</div>
													</div>
													<div className="item">
														<div className="value">{Math.round(((kpis.crossSellingRatio * 100 + Number.EPSILON) * 100) / 100)}%</div>
														<div className="caption">{L10n.__("Conversión cross-selling")}</div>
													</div>
												</div>}
										</div>
									)}
								</Bloque>
							)}
							{(!kpis || !types) && <LoadingIndicator />}
						</div>
					</>
				)}
			/>
		</div>
	);
}
