import React, { useState, useEffect } from 'react';
import { APIRequest } from '../services/API.js';
import { Link } from 'react-router-dom';
import FormInput from './FormInput.jsx';
import Session from './../services/Session';
import $ from 'jquery';
import LoadingIndicator from "./LoadingIndicator";
import { Content } from './Content.jsx';
import L10n from '../services/Locale.jsx';
import CountryCodeSelector from './CountryCodeSelector.jsx';
import Settings from '../services/Settings';

export default function Registro(props) {
    const [ errorMessage, setErrorMessage ] = useState();
    const [ login, setLogin ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ name, setName ] = useState("");
    const [ surname, setSurname ] = useState("");
    const [ phone, setPhone ] = useState("");
	const [ phoneCountryCode, setPhoneCountryCode ] = useState("");
    const [ country, setCountry ] = useState(null);
    const [ countries, setCountries ] = useState([]);
	const [ brandName, setBrandName ] = useState("");

    function onSubmit(e) {
        if(e) e.preventDefault();

        if($('.form-input.not-validated').length === 0) {
            setErrorMessage(null);
            Session.Register(login, password, name, surname, phoneCountryCode + phone, country).then(response => {
                if(response.status) {
                    sessionStorage.setItem('is_logged_in', '1');
                    if(!sessionStorage.getItem('login_redirect')) {
                        sessionStorage.setItem("login_redirect", "/cuenta-creada");
                    }
                    window.location.href = '/login';
                } else {
                    setErrorMessage(response.data.error);
                }
            });
        }

        return false;
    }

    useEffect(() => {
        setCountry(countries.find(c => c.name == "España")?.id || 163);
    }, [country]);

    useEffect(() => {
        APIRequest("users/register-get-countries").then(response => {
            setCountries(response.data);
        });

		Settings.Get("BRAND_NAME").then(value => {
			setBrandName(value);
		});
    }, []);

    if (!countries || !countries.length || brandName == "") return <LoadingIndicator />;

    return (
        <div className="registro">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 form-container">
						<h1>{L10n.__("Regístrate en " + brandName)}</h1>
                        <form autoComplete="off" onSubmit={onSubmit}>
                            <input type="hidden" autoComplete="off" />
                            <div className="columns">
                                <FormInput required
                                    autoFocus
                                    type="email"
                                    id="email"
                                    label={L10n.__("Email *")}
                                    onValidate={value => {
                                        // eslint-disable-next-line no-control-regex
                                        if(!value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)) return L10n.__("Por favor introduce una dirección de email válida.");
                                    }}
                                    onChange={value => {
                                        setLogin(value);
                                    }}
                                />
                                <FormInput required
                                    type="email"
                                    id="email-repetir"
                                    label={L10n.__("Repetir email *")}
                                    onValidate={value => {
                                        // eslint-disable-next-line no-control-regex
                                        if(!value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)) return L10n.__("Por favor introduce una dirección de email válida.");
                                        if(login !== value) return L10n.__("Los emails no coinciden.");
                                    }}
                                />
                            </div>
                            <div className="columns">
                                <FormInput required
                                    type="password"
                                    id="password"
                                    label={L10n.__("Contraseña *")}
                                    onValidate={value => {
                                        if(value.length < 8) return L10n.__("La contraseña debe tener al menos 8 caracteres. Una contraseña fuerte debe contener letras, números y símbolos.");
                                    }}
                                    onChange={value => {
                                        setPassword(value);
                                    }}
                                />
                                <FormInput required
                                    type="password"
                                    id="password2"
                                    label={L10n.__("Repetir contraseña *")}
                                    onValidate={value => {
                                        if(value.length && password !== value) return L10n.__("Las contraseñas no coinciden.");
                                    }}
                                />
                            </div>
                            <div className="columns">
                                <FormInput required type="text" id="nombre" label={L10n.__("Nombre *")}
                                onChange={value => {
                                    setName(value);
                                }} />
                                <FormInput required type="text" id="apellidos" label={L10n.__("Apellidos *")}
                                onChange={value => {
                                    setSurname(value);
                                }} />
							</div>
							<div className="columns">
								<div className="phone-cell">
									<CountryCodeSelector onChange={value => {
										setPhoneCountryCode(value);
									}} />
									<FormInput required type="phone" id="telefono" label={L10n.__("Teléfono *")}
										onChange={value => {
											setPhone(value);
										}} />
								</div>
                                {country !== null && <FormInput type="select" id="country" label="País" initialValue={country} options={countries.map(row => { return {label: row.name, value: row.id }; })} onChange={value => { setCountry(value) }} />}
                            </div>
										  
                            { errorMessage && <div className="error-message">{errorMessage}</div> }

                            <button style={{ gridColumn: 'span 2' }} className="btn btn-brown" onClick={onSubmit}>{L10n.__("Registrarse")}</button>
                            <div className="center" style={{ gridColumn: 'span 2' }}>{L10n.__("¿Ya tienes una cuenta?")} <Link to="/login">{L10n.__("Entra")}</Link>.</div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
