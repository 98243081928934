import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Events from '../services/Events';
import Session from '../services/Session';
import LoadingIndicator from '../components/LoadingIndicator';
import L10n from '../services/Locale';
import $ from 'jquery';
import SubscriptionsService from '../services/Subscriptions';
import { SubscriptionsContext } from '../context/SubscriptionsContext';
import { APIRequest } from '../services/API';

export default function SuscripcionCompletada(props) {
    const [ userData, setUserData ] = useState({});
    const [ confirmed, setConfirmed ] = useState(false);
    const [ loadingMessage, setLoadingMessage ] = useState("");
    const [ userSubscriptionID, setUserSubscriptionID ] = useState();
    const [ setupIntent, setSetupIntent ] = useState();
    
    const subscriptionsContext = useContext(SubscriptionsContext);

    useEffect(() => {
        if (location.search.match(/subscription_id=([^&]+)/i)) {
            setUserSubscriptionID(location.search.match(/subscription_id=([^&]+)/i)[1]);
        } else if (location.search.match(/setup_intent=([^&]+)/i)) {
            setSetupIntent(location.search.match(/setup_intent=([^&]+)/i)[1]);
        } else {
            setConfirmed(true);
        }
    }, [ location ]);

    useEffect(() => {
        if (setupIntent) {
            setLoadingMessage(L10n.__("Verificando el pago"));

            APIRequest("subscriptions/confirm", { setup_intent: setupIntent }).then(response => {
                if (!response.status) {
                    console.error("Ha fallado la confirmación (subscriptions/confirm).");
                } else {
                    location.search = '?subscription_id=' + response.data;
                }
            });
        } else if (userSubscriptionID) {
            setLoadingMessage(L10n.__("Confirmando suscripción"));
        
            const waitForStripe = (retries) => {
                APIRequest("subscriptions/update-payment-status", { user_subscription_id: userSubscriptionID }).then(async (response )=> {
                    if (response.data) {
                        await subscriptionsContext.reload();
                        setConfirmed(true);
                    } else if (retries <= 0) {
                        console.error("Ha fallado la confirmación (subscriptions/update-payment-status).");
                    } else {
                        setTimeout(() => {
                            waitForStripe(retries - 1);
                        }, 4000);
                    }
                });
            }

            setTimeout(() => {
                waitForStripe(20);
            }, 1000);
        }
    }, [ userSubscriptionID, setupIntent ]);

    useEffect(() => {
        const user_data = sessionStorage.getItem('user_data');
        if (user_data) {
            setUserData(JSON.parse(user_data));
        } else {
            Session.GetSession().then(session => {
                setUserData(session);
            });
        }

        $('header').addClass('simple');
        SubscriptionsService.ClearCartData();

        return () => {
            $('header').removeClass('simple');
        };
    }, []);

    if (!confirmed) {
        return <LoadingIndicator message={loadingMessage} />;
    }

    return (
        <div className="reserva-completada">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1>{L10n.__("Tu suscripción se ha completado con éxito")}</h1>
                        {userData && <h2>{L10n.__("Tan pronto la confirmemos recibirás un correo de confirmación en")} <span className="user-email">{userData.login}</span> {L10n.__("y en tu WhatsApp.")}<br/>{L10n.__("Recuerda comprobar también la carpeta de spam.")}</h2>}

                        <div className="buttons">
                            <Link to="/mis-suscripciones" className="btn btn-brown">{L10n.__("Ver mis suscripciones")}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

