import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router';
import { DialogContext } from '../context/DialogContext';
import { APIAdminRequest } from '../services/API';
import LoadingIndicator from './LoadingIndicator';
import Bloque from './AdminBloque';
import L10n from '../services/Locale';
import MaterialIcon from './MaterialIcon';

export default function AdminCiudades(props) {
    const [ finishedLoading, setFinishedLoading ] = useState(false);
    const [ cities, setCities ] = useState(null);
    const navigate = useNavigate();
	const dialogContext = useContext(DialogContext);

	useEffect(() => {
        APIAdminRequest("get-cities").then(response => {
            setCities(response.data);
            setFinishedLoading(true);
        });

		document.body.classList.add("bg-grey");

		return () => {
			document.body.classList.remove("bg-grey");
		};
	}, []);

    if (!finishedLoading) return <LoadingIndicator />;

	return (
		<div className="admin-page admin-ciudades">
			<Bloque>
                {cities != null && <div className="ciudades">
                    <h2>{L10n.__("Ciudades y zonas")}</h2>
                    <div className="ciudades-grid">
                        {cities.map((ciudad, ciudadIndex) => {
                            const image_url = location.hostname == "localhost" ? "http://localhost:8081/" + ciudad.image_url : ciudad.image_url;
                            return (
                                <div
                                    className="ciudad"
                                    key={"ciudad-" + ciudadIndex}
                                    onClick={() => {
                                        navigate("/admin/ciudades/" + ciudad.id);
                                    }}>
                                    <div className="image-container" style={{ backgroundImage: "url(" + image_url + ")" }}></div>
                                    <div className="details">
                                        <div className="nombre">{ciudad.name}</div>
                                    </div>
                                </div>
                            );
                        })}
                        <div
                            className="ciudad nueva-ciudad"
                            onClick={() => {
                                navigate("/admin/ciudades/0");
                            }}>
                            <MaterialIcon name="add_circle" />
                        </div>
                    </div>
                </div>}
			</Bloque>
		</div>
	);
}
