import React from "react";
import { Link } from "react-router-dom";
import ContentHelper from "../services/ContentHelper";
import LanguageSelector from "./LanguageSelector";
import L10n from "../services/Locale";
import Session from "../services/Session";
import $ from "jquery";
import Settings from "../services/Settings";
import SubscriptionsService from "../services/Subscriptions";

export default class MobileMenu extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			is_logged_in: false,
			logo: <></>,
			supportsLocalization: null,
			staticPageEnabled: null,
			subscriptionsEnabled: false
		};

		SubscriptionsService.IsEnabled().then((enabled) => {
			this.setState({ subscriptionsEnabled: enabled });
		});

		Session.CheckIsLoggedIn().then((is_logged_in) => {
			this.setState({ is_logged_in });
		});

		Session.GetBootstrapData().then((data) => {
			this.setState({ supportsLocalization: data.supports_localizatoin });
		});

		Settings.Get("SHOW_PAGINA_ESTATICA").then((value) => {
			this.setState({ staticPageEnabled: value });
		});
	}

	componentDidMount() {
		ContentHelper.GetLogoImg("logo").then((logo) => this.setState({ logo }));

		Promise.all([ContentHelper.GetString("custom_static_page_url"), ContentHelper.GetString("custom_static_page_caption")]).then(([url, caption]) => {
			let customStaticURL = "/para-empresas";
			let customStaticCaption = L10n.__("Para empresas");

			if (url && url.length) customStaticURL = url;
			if (caption && caption.length) customStaticCaption = caption;

			this.setState({
				customStaticURL,
				customStaticCaption
			});
		});

		window.addEventListener("scroll", this.handleScroll);
		this.handleScroll();
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
	}

	handleScroll(e) {
		if (window.scrollY > 1) {
			document.body.classList.add("scrolled");
		} else {
			document.body.classList.remove("scrolled");
		}
	}

	render() {
		return (
			<React.Fragment>
				<nav className="mobile-navbar">
					<button
						className="menu-button"
						onClick={() => {
							$("body").addClass("scroll-lock");
							$(".mobile-menu-overlay, .mobile-menu").addClass("active");
						}}>
						<img alt="" src="/static/icons/menu-icon.png" />
					</button>
					<Link to="/">{this.state.logo}</Link>
					<Link to="/restaurantes" className="reservar-ahora">
						{L10n.__("Reservar ahora")}
					</Link>
				</nav>
				<div className="mobile-menu-overlay" onClick={this.closeMenu} />
				<div className="mobile-menu">
					<div className="user-menu">{Session.RenderUserActions(this.closeMenu.bind(this))}</div>

					<Link to="/contacto" onClick={this.closeMenu}>
						Contacto
					</Link>
					<Link to="/preguntas-frecuentes" onClick={this.closeMenu}>
						{L10n.__("Preguntas frecuentes")}
					</Link>
					{this.state.staticPageEnabled == true && (
						<a href={this.state.customStaticURL} onClick={this.closeMenu}>
							{this.state.customStaticCaption}
						</a>
					)}

					{this.state.supportsLocalization && <LanguageSelector />}

					{this.state.subscriptionsEnabled && (
						<Link className="mis-suscripciones" onClick={this.closeMenu.bind(this)} to="/mis-suscripciones">
							{L10n.__("Mis suscripciones")}
						</Link>
					)}
				</div>
			</React.Fragment>
		);
	}

	closeMenu() {
		$("body").removeClass("scroll-lock");
		$(".mobile-menu-overlay, .mobile-menu").removeClass("active");
	}
}
