import React from 'react';
import $ from 'jquery';
import L10n from '../services/Locale';
import MaterialIcon from './MaterialIcon.jsx';

export default class FormInput extends React.Component {
	constructor(props) {
		super(props);

        const selectedOptionIndex = this.props.options?.findIndex(o => o.value == this.props.initialValue);
		
		this.state = {
			is_valid: true,
			validated: this.props.required ? false : true,
			empty: !this.props.initialValue,
			validation_message: '',
			selectActive: false,
			elementID: Math.floor(Math.random() * 1000000) + "-forminput",
			selectedOptionIndex: selectedOptionIndex == -1 ? 0 : selectedOptionIndex,
            visiblePassword: false
		};

		this.initialValueSet = false;
	}

	onBodyClick(e) {
		if (this.props.type == "select") {
			if (!e.target.classList.contains("selected-option")) {
				this.setState({ selectActive: false });
			}
		}
	}

	componentDidMount() {
		if (!this.initialValueSet) {
			this.initialValueSet = true;
			$("#" + this.props.id).val(this.props.initialValue || "");
		}

		document.body.addEventListener("click", this.onBodyClick.bind(this));
	}

	componentWillUnmount() {
		document.body.removeEventListener("click", this.onBodyClick.bind(this));
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.type == "select") {
			if (this.state.selectActive && !prevState.selectActive) {
				const top = document.getElementById(this.state.elementID).querySelector(".option-" + this.state.selectedOptionIndex)?.offsetTop;
				if (top) {
					document.getElementById(this.state.elementID).scrollTo(0, top - document.getElementById(this.state.elementID).getBoundingClientRect().height / 2);
				}
			}
		}
	}

	render() {
		if (this.props.type == "checkbox") {
			return <div className={"form-input checkbox" + (this.props.id ? this.props.id + "-container" : "") + (this.props.className || "")}>
                <label style={this.props.style}><input defaultChecked={this.props.initialValue} type="checkbox" id={this.props.id} onChange={e => {
					if (this.props.onChange) {
						this.props.onChange(e.target.checked);
					}
				}} /> {this.props.label}</label>
			</div>
		}

		if (this.props.type == "select" && this.props.options?.length) {
			return <div className={"form-input select " + (this.props.id ? this.props.id + "-container " : "") + (this.props.className || "")} style={this.props.style}>
                {this.props.label != null && <label>{this.props.label}</label>}
				<div className="selected-option" style={{ opacity: this.state.selectActive ? 0.5 : 1 }} onClick={e => this.setState({ selectActive: !this.state.selectActive })}>{this.props.options[this.state.selectedOptionIndex]?.label}</div>
				{this.state.selectActive && <div className="options-list" id={this.state.elementID}>
					{this.props.options.map((option, idx) => <div className={"option-" + idx + " option" + (this.state.selectedOptionIndex == idx ? " selected" : "")} key={this.props.id + "selectoption" + idx} onClick={e => {
						if (this.props.onChange) {
							this.props.onChange(option.value);
						}

						this.setState({
							selectedOptionIndex: idx,
							selectActive: false
						});
					}}>{option.label}</div>)}
				</div>}
			</div>
		}

		return (
			<div className={(this.state.validated ? 'validated ' : 'not-validated ' ) + 'form-input ' + this.props.id + '-container type-' + this.props.type + ' ' + (this.props.className || "")} style={this.props.style}>
				{ this.props.type === 'textarea' ?
				<textarea
					autoComplete="one-time-code"
					className={[ this.state.empty ? '' : 'non-empty', this.state.is_valid ? '' : 'non-valid' ].join(' ')} type={this.props.type}

					onChange={e => {
						this.setState({
							empty: e.target.value === ''
						});

						if(this.props.onChange) { this.setState({ state: e.target.value }); this.props.onChange(e.target.value); }
						this.forceUpdate();
					}}

					onBlur={e => {
						if(this.props.required && e.target.value.trim() === '') {
							this.setState({
								validation_message: L10n.__("Este campo es obligatorio."),
								is_valid: false,
								validated: false
							});
						} else if(this.props.onValidate) {
							const validation_result = this.props.onValidate(e.target.value);
							this.setState({
								validation_message: validation_result,
								is_valid: !validation_result,
								validated: !validation_result
							});
						} else {
							this.setState({
								validation_message: '',
								is_valid: true,
								validated: true
							});
						}
					}}

                    style={this.props.formStyle}

					id={this.props.id}
					name={this.props.id}
					defaultValue={this.props.initialValue || ""}
				/>
				:
                <input
                    autoComplete={this.props.type === 'password' ? 'new-password' : 'one-time-code'}
                    className={[ this.state.empty ? '' : 'non-empty', this.state.is_valid ? '' : 'non-valid' ].join(' ')} type={this.props.type == "password" && this.state.visiblePassword ? "text" : this.props.type}
					autoFocus={window.innerWidth >= 992 && this.props.autoFocus}
                    onChange={e => {
                        this.setState({
                            empty: e.target.value === ''
                        });

                        if(this.props.onChange) { this.setState({ state: e.target.value }); this.props.onChange(e.target.value); }
                        this.forceUpdate();
                    }}

                    defaultValue={this.props.initialValue}

                    onBlur={e => {
                        if(this.props.required && e.target.value.trim() === '') {
                            this.setState({
                                validation_message: L10n.__("Este campo es obligatorio."),
                                is_valid: false,
                                validated: false
                            });
                        } else if(this.props.onValidate) {
                            const validation_result = this.props.onValidate(e.target.value);
                            this.setState({
                                validation_message: validation_result,
                                is_valid: !validation_result,
                                validated: !validation_result
                            });
                        } else {
                            this.setState({
                                validation_message: '',
                                is_valid: true,
                                validated: true
                            });
                        }
                    }}

                    style={this.props.formStyle}

                    id={this.props.id}
                    name={this.props.id}
                />
				}
				<label htmlFor={this.props.id}>{this.props.label}</label>
                {this.props.type == "password" && <MaterialIcon className="visibility-button" name={!this.state.visiblePassword ? "visibility_off" : "visibility"} onClick={e => {
                    e.preventDefault();
                    this.setState({ visiblePassword: !this.state.visiblePassword });
                }}/>}
				<div className="validation-message">{this.state.validation_message}</div>
			</div>
		);
	}
}
