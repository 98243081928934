import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Events from '../services/Events';
import Carousel from './Carousel';
import Icon from './Icon';
import LoadingIndicator from "./LoadingIndicator";

export default function CarouselEventos(props) {
    const [elements, setElements] = useState([]);
    const [filteredElements, setFilteredElements] = useState([]);
    
    const navigate = useNavigate();

	const adminMode = props.adminMode;
	const filter = props.filter;
	const propsElements = props.elements;

	const addPlaceholderElement = useCallback(async elements => {
		const newElements = [...elements];
		if (adminMode) {
            const placeholder = await Events.CreatePlaceholder();
            placeholder.tipo = elements[0].tipo,
            placeholder.main = elements[0].main,
			newElements.push(placeholder);
		}
		return newElements;
	}, [adminMode]);

    useEffect(() => {
		let filteredElements = [];

        if (propsElements) {
            const elements = [...propsElements];
            if (filter) {
                filteredElements = elements.filter(filter);
            } else {
                filteredElements = elements;
            }
			setElements(elements);
			setFilteredElements(filteredElements);
        } else {
            Events.GetEventList().then(response => {
                const elements = [...response.data];
                if (filter) {
                    filteredElements = elements.filter(filter);
                } else {
                    filteredElements = elements;
				}
				setElements(elements);
				setFilteredElements(filteredElements);
            });
        }

    }, [addPlaceholderElement, filter, propsElements]);
    
    if (!elements.length) {
        return null;
    }

    if (!elements) return <div style={{
        position: "fixed",
        zIndex: 10,
        backgroundColor: "white",
        borderRadius: 15,
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)"
    }}>
        <LoadingIndicator />
    </div>;

    return (
        <Carousel
            className="event-carousel"
            items={props.items}
            gutter={props.gutter}
            elements={filteredElements}
            renderElement={(event, index) => {
				let bookingURL;
				if (props.adminMode) {
					bookingURL = '/admin/negocios/' + event.id;
				} else {
					bookingURL = '/venue/' + event.slug;
				}

                return (
                    <div key={index} style={{ position: 'relative' }}>
                        <div className="item-container">
                            <div onClick={e => navigate(bookingURL)} className="image-container" style={{ backgroundImage: 'url(/static/images/eventos/' + event.image_url }}>
                                {event.iconos && <div className="icons">
                                    {event.iconos.map((icono, idx) => {
                                        return (<div onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            return false;
                                        }} style={{ position: "relative" }} key={idx}>
                                            <Icon name={icono.name} pixelSize={32} />
                                            <div className="icon-tooltip">{icono.description}</div>
                                        </div>);
                                    })}
                                </div>}
                                <div className="details" onClick={e => {
                                    navigate(bookingURL);
                                }}>
                                    <div className="name">{event.name}</div>
                                    <div className="city">{event.city}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }}
        />
    );
}
