import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import ContentHelper from '../services/ContentHelper';
import L10n from '../services/Locale';
import Settings from '../services/Settings';

export default function Footer(props) {
	const [logo, setLogo] = useState();
	const [socialMediaLinks, setSocialMediaLinks] = useState();

	useEffect(() => {
		Settings.Get("LOGO_HEIGHT", 65).then((height) => {
			ContentHelper.GetLogoImg("logo", height * 0.75, {marginRight: "auto"}).then(element => setLogo(element));
			ContentHelper.GetSocialMediaLinks().then(links => setSocialMediaLinks(links));
		});
	}, []);
	return <footer>
			<div className="container full-width">
				<div className="row">
					<div className="col-md-6 menu-footer">
						<Link className="nav-link" to="/preguntas-frecuentes">{L10n.__("FAQ")}</Link>
						<Link className="nav-link" to="/contacto">{L10n.__("CONTACTO")}</Link>
						{socialMediaLinks}
					</div>
					<div className="col-md-6">

					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						{logo}
					</div>
					<div className="col-md-6 right footer-links">
						<div className="links">
							<Link to="/terminos-y-condiciones">{L10n.__("TÉRMINOS Y CONDICIONES")}</Link>
							<Link to="/politica-de-privacidad">{L10n.__("PRIVACIDAD")}</Link>
						</div>
					</div>
				</div>
			</div>
		</footer>;
}
