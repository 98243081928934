import { Fragment, useState, useEffect, useContext } from "react";
import { AdminStylesContext } from "./Admin";
import DotMenu from "./DotMenu";

import MaterialIcon from "./MaterialIcon";

import "../../css/table.css";

export default function Table(props) {
	const [expandedRowIndex, setExpandedRowIndex] = useState(-1);
	const [draggedRowIndex, setDraggedRowIndex] = useState(-1);
	const [droppedRowIndex, setDroppedRowIndex] = useState(-1);
	const [elementID, _setElementID] = useState("table-component-" + Math.floor(Math.random() * 10000));

	const adminStylesContext = useContext(AdminStylesContext);

	const isResponsive = window.innerWidth <= 992;

	const renderTotalizedRow = (totalizedRowItems, renderFunction = null) => {
		if (!props?.items?.length) return null;

		return (
			<div className="table-row-container">
				<div className="totalized-row table-row">
					{props.items[0].columns.map(
						(_, idx) =>
							(!isResponsive || !props.responsiveState || (props.responsiveState && props.responsiveState[idx])) && (
								<span style={{ textAlign: props.alignment ? props.alignment[idx] : null, width: props.widths && (isResponsive && props.responsiveWidths ? props.responsiveWidths[idx] : props.widths[idx]) != -1 ? 100 * ((isResponsive && props.responsiveWidths ? props.responsiveWidths[idx] : props.widths[idx]) / totalWidth) + "%" : null, flex: props.widths && (isResponsive && props.responsiveWidths ? props.responsiveWidths[idx] : props.widths[idx]) == -1 ? 1 : null }} className={"table-column" + (props.alignment ? " " + props.alignment[idx] : " left")} key={"column-" + idx}>
									{(renderFunction || props.totalizedRow?.renderColumn || ((_, __) => null))(idx, totalizedRowItems)}
								</span>
							)
					)}
					{props.renderDetails && (
						<div className="expand-button no-auto-width" style={{ opacity: 0 }}>
							<MaterialIcon name={"expand_more"} />
						</div>
					)}
				</div>
			</div>
		);
	};

	useEffect(() => {
		const onScroll = () => {
			const header = document.querySelector("#" + elementID + " .table-header");
			const table = document.getElementById(elementID);

			if (header) {
				const headerHeight = header.getBoundingClientRect().height;
				if (document.body.classList.contains("scrolled") && window.scrollY < table.offsetHeight - headerHeight) {
					header.style.position = "absolute";
					header.style.top = window.scrollY + "px";
					header.style.width = table.offsetWidth + "px";
					table.style.paddingTop = headerHeight + "px";
				} else {
					header.style.position = "";
					header.style.top = "";
					header.style.width = "";
					table.style.paddingTop = "";
				}
			}
		};
		window.addEventListener("scroll", onScroll);
		return () => {
			window.removeEventListener("scroll", onScroll);
		};
	}, []);

	let totalWidth = 0;
	if (isResponsive && props.responsiveWidths) {
		totalWidth = props.responsiveWidths.reduce((acc, v) => (v == -1 ? acc : acc + v), 0);
	} else if (props.widths) {
		totalWidth = props.widths.reduce((acc, v) => (v == -1 ? acc : acc + v), 0);
	}

	let previousTotalizeTestValue = null;
	let totalizedRowItems = [];

	return (
		<div className="table-component" id={elementID}>
			{props.header && props.header.length > 0 && (
				<div className="table-header">
					{props.showDragHandle && <span className="header-drag-handle-space" />}
					{props.header.map(
						(caption, idx) =>
							(!isResponsive || !props.responsiveState || (props.responsiveState && props.responsiveState[idx])) && (
								<span style={{ textAlign: props.alignment ? props.alignment[idx] : null, width: props.widths ? 100 * ((isResponsive && props.responsiveWidths ? props.responsiveWidths[idx] : props.widths[idx]) / totalWidth) + "%" : null }} key={"header-" + idx}>
									{caption}
								</span>
							)
					)}
					{props.items.findIndex((item) => item.data) != -1 && <span style={{ width: 44 }}>&nbsp;</span>}
				</div>
			)}
			{props.items &&
				props.items.length > 0 &&
				props.items.map((item, idx) => {
					let shouldRenderTotalizedRow = false;
					if (props.totalizedRow) {
						if (previousTotalizeTestValue != null && props.totalizedRow.testValue(item.data) != previousTotalizeTestValue) {
							shouldRenderTotalizedRow = true;
						} else {
							totalizedRowItems.push(item.data);
						}

						previousTotalizeTestValue = props.totalizedRow.testValue(item.data);
					}
					return (
						<Fragment key={"row-" + idx}>
							{shouldRenderTotalizedRow && (
								<>
									{(() => {
										shouldRenderTotalizedRow = false;
										const items = [...totalizedRowItems];
										totalizedRowItems = [item.data]; // Next item.
										return renderTotalizedRow(items);
									})()}
								</>
							)}
							<div className="table-row-container">
								<div
									className={"table-row" + (expandedRowIndex == idx ? " expanded" : "") + (droppedRowIndex == idx ? " dropped-row" : "") + (draggedRowIndex == idx ? " dragged-row" : "") + (!props.showDetailsArrowVisible || props.showDetailsArrowVisible(item.data) ? " has-details" : "")}
									onMouseUp={(e) => {
										if (props.showDragHandle && draggedRowIndex != -1 && droppedRowIndex == idx) {
											let indexes = props.items.map((_, i) => i);
											indexes.splice(idx, 0, indexes.splice(draggedRowIndex, 1)[0]);
											if (props.onOrderChanged) props.onOrderChanged(indexes);

											setDraggedRowIndex(-1);
											setDroppedRowIndex(-1);
										}
									}}
									onMouseOver={(e) => {
										if (props.showDragHandle && draggedRowIndex != -1) {
											setDroppedRowIndex(idx);
										}
									}}
									onMouseOut={(e) => {
										if (props.showDragHandle && draggedRowIndex != -1 && droppedRowIndex == idx) {
											setDroppedRowIndex(-1);
										}
									}}>
									{props.showDragHandle && (
										<div className="drag-handle-column table-column">
											<MaterialIcon
												name="drag_handle"
												className="drag-handle"
												onMouseDown={(e) => {
													setDraggedRowIndex(idx);
												}}
											/>
										</div>
									)}
									{props.submenu && <DotMenu options={props.submenu?.map((o) => ({ ...o, action: () => o.action(item.data) }))} />}
									{item.columns.map(
										(column, idx) =>
											(!isResponsive || !props.responsiveState || (props.responsiveState && props.responsiveState[idx])) && (
												<span style={{ textAlign: props.alignment ? props.alignment[idx] : null, width: props.widths && (isResponsive && props.responsiveWidths ? props.responsiveWidths[idx] : props.widths[idx]) != -1 ? 100 * ((isResponsive && props.responsiveWidths ? props.responsiveWidths[idx] : props.widths[idx]) / totalWidth) + "%" : null, flex: props.widths && (isResponsive && props.responsiveWidths ? props.responsiveWidths[idx] : props.widths[idx]) == -1 ? 1 : null }} className={"table-column" + (props.alignment ? " " + props.alignment[idx] : " left")} key={"column-" + idx}>
													{column}
												</span>
											)
									)}
									{props.renderDetails && item.data && (
										<div
											className="expand-button no-auto-width"
											onClick={() => {
												if (expandedRowIndex == idx) {
													if (!props.willCloseDetails) {
														setExpandedRowIndex(-1);
													} else {
														props.willCloseDetails((answer) => {
															if (answer) {
																setExpandedRowIndex(-1);
															}
														});
													}
												} else {
													if (expandedRowIndex == -1) {
														if (props.onOpenDetails) props.onOpenDetails(item.data, idx);
														setExpandedRowIndex(idx);
													} else if (!props.willCloseDetails) {
														if (props.onOpenDetails) props.onOpenDetails(item.data, idx);
														setExpandedRowIndex(idx);
													} else {
														props.willCloseDetails((answer) => {
															if (answer) {
																if (props.onOpenDetails) props.onOpenDetails(item.data, idx);
																setExpandedRowIndex(idx);
															}
														});
													}
												}
											}}>
											<MaterialIcon name={expandedRowIndex == idx ? "expand_less" : "expand_more"} />
										</div>
									)}
								</div>
								{expandedRowIndex == idx && props.renderDetails && (
									<div className="row-details" key={"row-details-" + idx}>
										{props.renderDetails(item.data, idx)}
									</div>
								)}
							</div>
						</Fragment>
					);
				})}
			{props.totalizedRow && renderTotalizedRow(totalizedRowItems)}
			{props.renderGrandTotal
				? renderTotalizedRow(
						props.items.map((item) => item.data),
						props.renderGrandTotal
					)
				: null}
		</div>
	);
}
