import { useState, useEffect, useContext, useRef } from "react";
import ImageUpload from "./ImageUpload";
import IconSelector from "./IconSelector";
import Settings from "../services/Settings";
import LoadingIndicator from "./LoadingIndicator";
import MaterialIcon from "./MaterialIcon";
import Calendar from "./Calendar";
import Helper from "../services/Helper";
import { APIRequest } from "../services/API";

function MaxLengthIndicator(props) {
	return <div className="max-length-indicator">{props.length} / {props.maxLength}</div>;
}

export default function Field(props) {
	const isCurrencyField = props.type == "currency";
	const [plainTextValue, setPlainTextValue] = useState(toPlainText(props.defaultValue));
	const [currentValue, setCurrentValue] = useState(props.defaultValue);
	const [currencySymbolPlacement, setCurrencySymbolPlacement] = useState();
	const [currencySymbol, setCurrencySymbol] = useState();

	const [calendarIsVisible, setCalendarIsVisible] = useState(false);
	const [calendarPosition, setCalendarPosition] = useState({ top: 0, left: 0 });
	const [calendarOpacity, setCalendarOpacity] = useState(0);
    const addressValidationRef = useRef();

	let defaultValue = props.defaultValue;
	const elementID = props.type + "-" + Math.floor(Math.random() * 1000000);

	if (props.type == "strings" && defaultValue) {
		defaultValue = defaultValue.join("\n");
	}

	if (props.type == "number") {
		if (typeof props.max !== "undefined") defaultValue = Math.max(0, Math.min(props.max, defaultValue));
		else if (typeof props.max !== "undefined") defaultValue = Math.min(props.max, defaultValue);
	}

	const onBodyClick = e => {
		if (props.type == "date") {
			if (e.target.classList.contains("calendar") || $(e.target).closest(".calendar:visible").length) return;
			e.preventDefault();
			setCalendarIsVisible(false);
			$("body").off("click", onBodyClick);
		}
	};

	function toPlainText(text) {
		if (!text?.replaceAll) return text;
		return text?.replaceAll("\n", "").replaceAll(/<\/p>\s*<p>/gi, "\n\n").replaceAll("<p>", "").replaceAll("</p>", "");
	}

	useEffect(() => {
		if (calendarIsVisible) {
			setTimeout(() => {
				if (!calendarIsVisible) return;
				$("body").on("click", onBodyClick);
				const rect = document.querySelector(".calendar")?.getBoundingClientRect();

				if (!rect) return;
				let newLeft = calendarPosition.left;
				let newTop = calendarPosition.top;

				if (rect.left + rect.width > window.innerWidth - 30) {
					newLeft = window.innerWidth - rect.width - 30;
				}

				if (rect.top + rect.height > window.innerheight - 30) {
					newTop = window.innerHeight - rect.height - 30;
				}

				setCalendarOpacity(1);
				setCalendarPosition({ left: newLeft, top: newTop });
			}, 100);
		} else {
			setCalendarOpacity(0);
			$("body").off("click", onBodyClick);
		}
	}, [ calendarIsVisible ]);

	useEffect(() => {
		if (isCurrencyField) {
			Promise.all([Settings.Get("CURRENCY_SYMBOL_PLACEMENT", "after"), Settings.Get("CURRENCY_SYMBOL", "€")]).then(([placement, symbol]) => {
				setCurrencySymbolPlacement(placement);
				setCurrencySymbol(symbol);
			});
		}

		return () => {
			$("body").off("click", onBodyClick);
		}
	}, []);

	if (isCurrencyField && (!currencySymbol || !currencySymbolPlacement)) return <LoadingIndicator />;

	return (
		<div id={elementID} style={props.style} className={"custom-field type-" + props.type + (props.className ? " " + props.className : "") + (props.tooltip ? " has-tooltip" : "")} data-tooltip={props.tooltip}>
			{props.title && props.title.length && (
				<>
					<label>
						{props.title} <span>{props.optional == true && " (opcional)"}</span>
					</label>
				</>
			)}

			{(props.type == "string" || props.type == "address") && (
				<input
					id={props.id}
					type="text"
					disabled={props.disabled}
					placeholder={props.placeholder}
					defaultValue={defaultValue}
					maxLength={props.maxLength}
                    onInput={e => {
                        if (props.type == "address") {
                            if (e.target.value.length > 0) {
                                clearTimeout(addressValidationRef.current);
                                addressValidationRef.current = setTimeout(() => {
                                    APIRequest("address/validate", { input: e.target.value }).then(response => {
                                        if (response.data?.error || !response.data) {
                                            $(".address-validation-popup").remove();
                                        } else {
                                            const address = response.data.result.address.formattedAddress;
                                                
                                            $(".address-validation-popup").remove();
                                            $(e.target).parent().append('<div class="address-validation-popup">' + address + '</div>');

                                            $(".address-validation-popup").on("click", _ => {
                                                $(".address-validation-popup").remove();
                                                e.target.value = address;
                                                if (props.onChange) props.onChange(address); 
                                            });
                                        }
                                    });
                                }, 500);
                            } else {
                                clearTimeout(addressValidationRef.current);
                                $(".address-validation-popup").remove();
                            }
                        }
                    }}
                    onKeyDown={e => {
                        if (props.type == "address" && $(".address-validation-popup").length) {
                            if (e.key == "ArrowDown" || e.key == "Tab") {
                                e.preventDefault();
                                $(".address-validation-popup").addClass("active");
                            }

                            if (e.key == "Enter" && $(".address-validation-popup").hasClass("active")) {
                                const value = $(".address-validation-popup").text();
                                e.target.value = value;
                                if (props.onChange) props.onChange(value); 
                                $(".address-validation-popup").remove();
                            }
                        }
                    }}
                    onBlur={e => {
                        setTimeout(() => {
                            $(".address-validation-popup").remove();
                        }, 500);
                    }}
					onChange={(e) => {
						setCurrentValue(e.target.value);
						props.onChange(e.target.value);
					}}
				/>
			)}

			{props.type == "date" && (
				<div className="date-value" 
				id={props.id}
				onClick={e => {
					e.preventDefault();
					if (!calendarIsVisible) {
						setCalendarPosition({ left: e.pageX, top: e.pageY });
						setCalendarIsVisible(true);
					}
				}}>{currentValue ? Helper.FormatDate(currentValue, Helper.DATE_VERBOSE_SHORTEST) : (props.placeholder || "dd/mm/aaaa")}<MaterialIcon name={currentValue ? "close" : "calendar_today"} onClick={e => {
					if (currentValue) {
						e.preventDefault();
						e.stopPropagation();
						setCurrentValue(null);
						props.onChange(null);
					}
				}} />{calendarIsVisible && <Calendar
					style={{ left: calendarPosition.left, top: calendarPosition.top, opacity: calendarOpacity }}
					months={1}
					allowPast={false}
					onSelectionChanged={dates => {
						if (!dates || ! dates.length) {
							setCurrentValue(null);
							props.onChange(e.target.value);
							return;
						}
						
						setCalendarIsVisible(false);
						setCurrentValue(Helper.GetISODate(dates[0]));
						props.onChange(Helper.GetISODate(dates[0]));
					}}
				/>}</div>
			)}

			{props.type == "currency" && (
				<div className="currency-field-container">
					<input
						id={props.id}
						type="text"
						style={{
							width: "calc(75px + " + (0.70 * (props.currencySymbol || currencySymbol).length) + "em)",
							paddingRight: (props.currencySymbolPlacement || currencySymbolPlacement) == "after" && (0.7 + 0.70 * (props.currencySymbol || currencySymbol).length + "em"),
							paddingLeft: (props.currencySymbolPlacement || currencySymbolPlacement) == "before" &&  (0.7 + 0.70 * (props.currencySymbol || currencySymbol).length + "em")
						}}
						disabled={props.disabled}
						className={"currency symbol-" + (props.currencySymbolPlacement || currencySymbolPlacement)}
						defaultValue={Helper.FormatAmount(defaultValue / 100, false, false)}
						onChange={(e) => {
							if (e.target.value == "") e.target.value = 0;
							setCurrentValue(e.target.value);
							props.onChange(Helper.ParseAmount(e.target.value) * 100);
						}}
					/>
					<div className={"currency-symbol placement-" + (props.currencySymbolPlacement || currencySymbolPlacement)}>{(props.currencySymbol || currencySymbol)}</div>
				</div>
			)}

			{props.type == "number" && (
				<div className="number-field-container">
					{props.defaultValue != -1 && <input
						id={props.id}
						type="text"
						disabled={props.disabled}
						style={props.inputStyle || { textAlign: props.unitStrings ? "right" : "center" }}
						placeholder={props.placeholder}
						defaultValue={defaultValue}
						onBeforeInput={(e) => {
							if (!Number.isInteger(parseInt(e.data))) e.preventDefault();
						}}
						onChange={(e) => {
							if (e.target.value != "") {
								if (typeof props.min !== "undefined" && typeof props.max !== "undefined") e.target.value = Math.max(props.min, Math.min(props.max, e.target.value));
								else if (typeof props.min !== "undefined") e.target.value = Math.max(props.min, e.target.value);
								else if (typeof props.max !== "undefined") e.target.value = Math.min(props.max, e.target.value);
							}
							setCurrentValue(e.target.value);
							props.onChange(e.target.value);
						}}
						onBlur={(e) => {
							if (e.target.value == "") {
								e.target.value = props.min || 0;
							}
						}}
					/>}
					{props.unitStrings && (currentValue == 1 ? props.unitStrings[0] : props.unitStrings[1])}
					<div
						className={"minus" + (props.disabled ? " disabled" : "")}
						onClick={(e) => {
							if (props.disabled) return;
							const input = document.querySelector("#" + elementID + " input");
							input.value = parseInt(input.value) - 1;
							if (typeof props.min !== "undefined") input.value = Math.max(props.min, input.value);
							setCurrentValue(input.value);
							props.onChange(input.value);
						}}
						style={{ marginLeft: props.unitStrings ? "0.75em" : "0.25em" }}>
						<img src="/static/icons/minus.svg" />
					</div>
					<div
						className={"plus" + (props.disabled ? " disabled" : "")}
						onClick={(e) => {
							if (props.disabled) return;
							const input = document.querySelector("#" + elementID + " input");
							input.value = parseInt(input.value) + 1;
							setCurrentValue(input.value);
							props.onChange(input.value);
							if (typeof props.max !== "undefined") input.value = Math.min(props.max, input.value);
						}}>
						<img src="/static/icons/plus.svg" />
					</div>
				</div>
			)}

			{props.type == "password" && (
				<input
					id={props.id}
					style={props.style}
					type="password"
					autoComplete="new-password"
					disabled={props.disabled}
					placeholder={props.placeholder}
					defaultValue={defaultValue}
					onChange={(e) => {
						setCurrentValue(e.target.value);
						props.onChange(e.target.value);
					}}
				/>
			)}

			{(props.type == "text" || props.type == "strings") && (
				<div style={{position:"relative"}}>
					{props.maxLength && <MaxLengthIndicator length={toPlainText(currentValue).length} maxLength={props.maxLength} />}
					<textarea
						id={props.id}
						disabled={props.disabled}
						maxLength={props.maxLength}
						style={props.style}
						placeholder={props.placeholder}
						defaultValue={
							props.type == "strings"
								? defaultValue
								: defaultValue &&
								toPlainText(defaultValue)
						}
						onChange={(e) => {
							let value = e.target.value;
							if (props.type == "strings") {
								value = value.split("\n").filter((v) => v);
							} else if (props.type == "text" && props.enableHTML !== false) {
								value = value.replaceAll(/\n?([^\n]+)\n?/gi, "<p>$1</p>").trim();
							}
							setPlainTextValue(toPlainText(value));
							setCurrentValue(value);
							props.onChange(value);
						}}
					/>
				</div>
			)}

			{props.type == "image" && (
				<ImageUpload
					id={props.id}
					alwaysShowLimitsInfo={props.alwaysShowLimitsInfo}
					recommendedWidth={props.recommendedWidth}
					recommendedHeight={props.recommendedHeight}
					maxFileSize={props.maxFileSize}
					fileFormats={props.fileFormats}
					limitsInfoPosition={props.limitsInfoPosition}	
					limitsInfoPositionOffset={props.limitsInfoPositionOffset}
					disabled={props.disabled}
					initialValue={defaultValue}
					onChange={(value) => {
						setCurrentValue(value);
						props.onChange(value);
					}}
				/>
			)}

			{props.type == "icon" && (
				<IconSelector
					id={props.id}
					disabled={props.disabled}
					initialValue={defaultValue}
					onChange={(value) => {
						setCurrentValue(value);
						props.onChange(value);
					}}
				/>
			)}
		</div>
	);
}
